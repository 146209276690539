/** @jsx jsx */
// @ts-ignore
import { Container, Text, Box, jsx, Flex } from 'theme-ui'
import { FC, Fragment } from 'react'
import Layout from '~/components/layout'
import HeroImage from '~/components/hero-image'
import MarkdownIt from '~/components/MarkdownIt'
import { GatsbyImageType } from '~/@types/models'
import ProductCarousel from '~/components/products-carousel'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import HomepageHeader from '~/components/homepage/header'
import HeadingV2 from '~/componentsV2/heading'
import { OpeningsV2 } from '~/componentsV2/openings'
import { ShowroomWrapper } from '~/componentsV2/markDownPages/showroons'
import theme from '~/gatsby-plugin-theme-ui'
import PageHeader2 from '~/components/homepage/header2'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'

export type CenturionMembersTemplateProps = {
  data: {
    frontmatter: {
      header: {
        title: string
        description: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      showroomTitle: string
      headerQuote: string
      showroomLink: string
      address: {
        line1: string
        line2: string
      }
      openHours: Array<{
        day: string
        time: string
      }>
      images?: ReadonlyArray<{
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }>
      carousel?: ReadonlyArray<{
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }>
    }
    html: string
  }
}

export const ShoowroomLink = ({
  href,
  children,
}: {
  href: string | undefined
  children: React.ReactNode
}) => {
  if (!href) return null

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer"
      sx={{
        display: 'block',
        color: 'inherit',
        textDecoration: 'underline',
        marginTop: 20,
        fontSize: [16, null, 18],
      }}
    >
      {children}
    </a>
  )
}

const CenturionMembersTemplate: FC<CenturionMembersTemplateProps> = ({ data }) => {
  const {
    html: content,
    frontmatter: { header, carousel, images },
  } = data

  const HeaderInfos = ({ textColor }: { textColor: 'light' | 'dark' }) => (
    <Fragment>
      <HeadingV2
        className="product-detail-panel__title"
        sx={{
          textAlign: 'initial',
          color: ['#000', null, '#fff'],
          fontSize: ['26px !important', null, '48px !important'],
          textTransform: 'uppercase',
          fontWeight: 400,
          paddingRight: 0,
          marginBottom: [10, 20, 40],
        }}
      >
        Ben soleimani’s <br /> curated centurion <br /> collection
      </HeadingV2>
    </Fragment>
  )

  return (
    <Layout topBanner={false} title={header.title} description={header.description}>
      <PageHeader2
        title={
          <Box
            sx={{
              display: ['none', null, 'block'],
            }}
          >
            <HeaderInfos textColor="light" />
          </Box>
        }
        infoContainerSx={{
          width: [300, 750, 900, 1250],
        }}
        heroSx={{
          justifyContent: 'center !important',
          alignItems: 'center',
        }}
        titleProps={{
          fontSize: '32px !important',
          textTransform: 'uppercase',
          fontWeight: '400 !important',
          paddingRight: 0,
          marginBottom: 0,
        }}
        images={{
          default: header.image,
          mobile: header.imageMobile,
        }}
        contentPosition="left"
      />
      <Container variant="containerPrimary">
        <Box
          sx={{
            display: ['block', null, 'none'],
            pt: 20,
          }}
        >
          <HeaderInfos textColor="dark" />
        </Box>
        <Box my={['l', null, 'xl']}>
          <ShowroomWrapper>
            <MarkdownIt content={content} />
          </ShowroomWrapper>
          <Flex
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: [theme.space.l, null, theme.space.l],
              flexDirection: ['column'],
            }}
          >
            <ShoowroomLink href="/">EXPLORE MORE</ShoowroomLink>
          </Flex>
        </Box>
      </Container>
      <Box>
        {images?.map(item => {
          return (
            <Link to="/">
              <HeroImage
                images={{
                  default: item.image,
                  mobile: item.imageMobile,
                }}
                height="auto"
              />
            </Link>
          )
        })}
      </Box>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
        {
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "Ben Soleimani",
  "image": "https://bensoleimani.com/static/386b41055b6473cd67935362c179ee26/ab8d3/WebNew%20York%20Showroom%201.webp",
  "@id": "https://bensoleimani.com/pages/nyc-showroom#nyc",
  "url": "https://bensoleimani.com/pages/nyc-showroom",
  "description": "Located at 601 Madison Avenue at 57th and Madison, the premiere Ben Soleimani Flagship New York showroom features over 12,000 square feet of luxury storefront spanning across three entire floors. The showroom sublimely highlights Soleimani’s iconic signature luxury home designs.",
  "telephone": "(212) 464-7131",
  "sameAs": [
    "https://www.facebook.com/bensoleimanihome",
    "https://www.instagram.com/bensoleimani",
    "https://www.youtube.com/@bensoleimani3278",
    "https://www.linkedin.com/company/ben-soleimani"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "601 Madison Avenue",
    "addressLocality": "New York",
    "addressRegion": "NY",
    "postalCode": "10022",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 40.7624967,
    "longitude": -73.9717626
  },
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      "opens": "09:00",
      "closes": "18:00"
    }
  ],
  "priceRange": "$$$",
  "currenciesAccepted": "USD",
  "paymentAccepted": ["Credit Card", "Cash", "Apple Pay"],
  "hasMap": "https://www.google.com/maps/place/Ben+Soleimani/@40.7624967,-73.9717626,15z/data=!4m6!3m5!1s0x89c259d9571cc793:0xcb9fee9a739ce6a2!8m2!3d40.7624967!4d-73.9717626!16s%2Fg%2F11smz9s9v8?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D",
  "isAccessibleForFree": "true",
  "amenityFeature": [
    {
      "@type": "LocationFeatureSpecification",
      "name": "Wheelchair Accessible",
      "value": "true"
    }
  ],
  "founder": {
    "@type": "Person",
    "name": "Ben Soleimani",
    "sameAs": "https://bensoleimani.com/pages/about-ben"
  }
}
    `,
        }}
      />
    </Layout>
  )
}

export default CenturionMembersTemplate
