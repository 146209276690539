import { graphql } from 'gatsby'
import React from 'react'
import CenturionMembersTemplate, {
  CenturionMembersTemplateProps,
} from '~/templates/centurion-members'

export const query = graphql`
  query {
    pageData: markdownRemark(frontmatter: { templateKey: { eq: "centurion-members" } }) {
      frontmatter {
        headerVariant
        header {
          title
          description
          image {
            ...heroImage
          }
          imageMobile {
            ...mobileHeroImage
          }
        }
        showroomTitle
        openHours {
          day
          time
        }
        headerQuote
        showroomLink
        address {
          line1
          line2
        }
        images {
          image {
            ...heroImage
          }
          imageMobile {
            ...mobileHeroImage
          }
        }
      }
      html
    }
  }
`

type Props = {
  data: {
    pageData: CenturionMembersTemplateProps['data']
  }
}

const CenturionMembers = ({ data: { pageData } }: Props) => (
  <CenturionMembersTemplate data={pageData} />
)

export default CenturionMembers
