/** @jsx jsx */
import { ReactNode } from 'react'
import { jsx, Container, Text, Box, Flex, ThemeUICSSObject } from 'theme-ui'
import { ImageTypes } from '~/@types/models'
import HeroImage from '~/components/hero-image'
import HeadingV2 from '~/componentsV2/heading'
import { Link } from '~/componentsV2/linkPrev/LinkPrev'
import { mapPositionToFlex } from '~/utils/common'

interface Props {
  title?: ReactNode | string
  description?: string
  link?: string
  titleColor?: string
  descriptionColor?: string
  infoContainerSx?: ThemeUICSSObject
  heroSx?: ThemeUICSSObject
  images?: {
    default: any
    mobile?: any
  } | null
  contentPosition?: 'left' | 'center' | 'right'
  titleProps?: ThemeUICSSObject
  children?: ReactNode
  imageV2?: ImageTypes['imageV2']
  imageSrc?: string
  showContent?: boolean
}

const PageHeader2 = ({
  title,
  description,
  link,
  titleColor,
  descriptionColor,
  images,
  contentPosition = 'center',
  titleProps,
  children,
  infoContainerSx,
  heroSx,
  imageV2,
  showContent = true,
}: Props) => {
  return (
    <Link
      to={link}
      sx={{
        position: 'relative',
        '&:hover': {
          '#collection-link': {
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#00233C',
            opacity: 0.35,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr'],
          backgroundColor: 'bgDarkBlue',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {showContent ? (
            <Box
              as={Flex}
              sx={{
                maxWidth: 700,
                mx: 'auto',
                mt: ['l', null, null, 'xl2n'],
                justifyContent: ['initial', null, mapPositionToFlex(contentPosition)],
                ...infoContainerSx,
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                {title && typeof title === 'string' && (
                  <HeadingV2
                    className="product-detail-panel__title"
                    sx={{
                      textAlign: 'initial',
                      color: titleColor === 'dark' ? 'navDark' : 'inherit',
                      fontSize: '2rem !important',
                      ...titleProps,
                    }}
                  >
                    {title}
                  </HeadingV2>
                )}
                {title && typeof title !== 'string' && title}
                {description && (
                  <Container
                    sx={{
                      maxWidth: 480,
                      p: 0,
                      // m: 0,
                    }}
                  >
                    <Text
                      variant="regular"
                      sx={{
                        color: descriptionColor === 'dark' ? 'navDark' : 'inherit',
                        display: ['none', 'block'],
                      }}
                    >
                      {description}
                      {children ? children : null}
                    </Text>
                    {!description ? children && children : null}
                  </Container>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
        <HeroImage
          images={images}
          imageV2={imageV2}
          height="auto"
          heroSx={{
            justifyContent: ['end', 'end', null, null],
            ...heroSx,
          }}
        />
      </Box>
    </Link>
  )
}

export default PageHeader2
